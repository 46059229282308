import slidesMississippiNutrientLoad from "./N-P-Loads-Mississippi/slides.html";
import thumbnailMississippiNutrientLoad from "./N-P-Loads-Mississippi/thumbnail.png";
import slidesNutrientLossIllinois from "./Nutrient-Loss-Illinois/slides.html";
import thumbnailNutrientLossIllinois from "./Nutrient-Loss-Illinois/thumbnail.png";
import thumbnailExploreData from "./Explore-Data-GLTG/thumbnail.png";
import slidesLayers from "./Layers/slides.html";
import thumbnailLayers from "./Layers/thumbnail.png";
import thumbnailDataCollection from "./Data-Collection/thumbnail.png";
import thumbnailContinuousMonitoring from "./Continuous-Monitoring/thumbnail.png";
import thumbnailBestManagementPractices from "./Best-Management-Practices/thumbnail.png";
import thumbnailCropScape from "./Crop-Scape/thumbnail.png";
import thumbnailNOAA from "./NOAA/thumbnail.png";
import thumbnailSparrowModel from "./Sparrow-Model/thumbnail.png";
import slidesTrends from "./Trends/slides.html";
import thumbnailTrends from "./Trends/thumbnail.png";

export default [
	{
		title: "Evaluating Nitrogen and Phosphorus Loads in Mississippi",
		slides: slidesMississippiNutrientLoad,
		thumbnail: thumbnailMississippiNutrientLoad,
	},
	{
		title: "Illinois Nutrient-Loss Reduction Strategy",
		slides: slidesNutrientLossIllinois,
		thumbnail: thumbnailNutrientLossIllinois,
	},
	{
		title: "What Are Layers and Why Do They Matter?",
		slides: slidesLayers,
		thumbnail: thumbnailLayers,
	},
	{
		title: "Tracking Trends with GLTG",
		slides: slidesTrends,
		thumbnail: thumbnailTrends,
	},
	{
		title: "Exploring Data in the GLTG Portal",
		thumbnail: thumbnailExploreData,
	},
	{
		title: "All About Data Collection",
		thumbnail: thumbnailDataCollection,
	},
	{
		title: "Continuous Monitoring",
		thumbnail: thumbnailContinuousMonitoring,
	},
	{
		title: "Best Management Practices",
		thumbnail: thumbnailBestManagementPractices,
	},
	{
		title: "Crop Scape",
		thumbnail: thumbnailCropScape,
	},
	{
		title: "NOAA",
		thumbnail: thumbnailNOAA,
	},
	{
		title: "Sparrow Model",
		thumbnail: thumbnailSparrowModel,
	},
];
